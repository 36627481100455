<template>  
  <section class="hero is-fullheight">
    <div class="hero-head">
      <Navbar />
    </div>
    <div class="hero-body">
      <div class="container">

        <div style="max-width: 640px;">
          <h1 class="subtitle is-size-3">
            have questions?
          </h1>
          <p class="mb-6">If you believe there is room for improvement or have any queries regarding our offerings, please do not hesitate to contact us.</p>
          
          <FormField
            label="Email"
            type="email"
            v-model="email"
          />

          <FormField
            label="Name"
            v-model="name"
          />

          <FormField
            label="Message"
            type="textarea"
            v-model="message"
          />

          <button class="button is-black" @click="handleSubmit()">
            Send message
          </button>
        </div>
      
      </div>
    </div>
    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import FormField from '@/components/Forms/FormField.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { Navbar, FormField, Footer },
  setup() {
    const email = ref('')
    const name = ref('')
    const message = ref('')

    const handleSubmit = async () => {
      alert('handleSubmit')
    }

    return {
      email,
      name,
      message,

      handleSubmit,
    }
  }
}
</script>